/* Generated by Glyphter (http://www.glyphter.com) on  Thu Oct 29 2020*/
@font-face {
    font-family: 'ChuChubeFont';
    src: url('../webfonts/ChuChubeFont.eot');
    src: url('../webfonts/ChuChubeFont.eot?#iefix') format('embedded-opentype'),
         url('../webfonts/ChuChubeFont.woff') format('woff'),
         url('../webfonts/ChuChubeFont.ttf') format('truetype'),
         url('../webfonts/ChuChubeFont.svg#ChuChubeFont') format('svg');
    font-weight: normal;
    font-style: normal;
}
.ccf{
	display: inline-block;
   font-family: 'ChuChubeFont';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}
.ccf-logo:before{content:'\0041';}
.ccf-repeat:before{content:'\0042';}
.ccf-logo-bg:before{content:'\0043';}
.ccf-logo-train:before{content:'\0044';}