@font-face {
    font-family: "Kenyan Coffee";
    src: url("./../webfonts/kenyc.ttf");
}

@media (min-width: 1500px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1440px;
    }
}

.container {
    margin-top: 80px
}

body {
    font-family: "Open Sans", sans-serif;
}

input, select, textarea, button {
    font-family: inherit;
}

.navBarLogo {
    position: absolute;
    top: 20px;
    left: 20px
}

.text-chuchube-red {
    color: #ff0000ff;
}

.ccf .alignMiddle {
    vertical-align: middle;
    margin-top: -20%;
}

@keyframes slidein {
    from {
        margin-left: -90%;
    }
    to {
        margin-left: 100%;
    }

}

.loadingLogo {
    position: absolute;
    left: 0;
    top: 22%;
    font-size: 44pt;
}

.loadingLogoBg {
    position: absolute;
    left: 0;
    top: 0;
}

.loadingLogo {
    animation: 1.5s cubic-bezier(0.4, 1.41, 0.68, -0.14) infinite slidein;
}


.loadingLogoContainingBlock {
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: 1em;
    height: 1em;
}

.loadingLogoContainer {
    text-align: center;
}

.container {
    margin-bottom: 20px;
}

.playerPlaceholder {
    border: dashed lightgray;
    border-radius: 5%;
    padding-top: 23%;
}

.playerContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.playerContainer iframe, .playerContainer .playerPlaceholder {
    position: absolute;
    top: 0;
    /*left: 0;*/
    width: 100%;
    height: 100%
}

.closePlayer {
    font-size: 18pt;
    color: black;
    padding: 0 12px;
}

.searchResultChannel, .videoListCardChannel {
    font-style: italic;
}

.searchResultDescription, .videoListCardDescription {
    font-size: small;
}

.searchResult {
    cursor: pointer;
}

.searchResult, .videoListCard {
    height: 126px;
}

.searchResultTextContainer, .videoListCardTextContainer {
    height: 101px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.thumbnailContainer {
    position: relative;
    width: 120px;
    height: 90px;
}

.thumbnailImage {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0
}

.thumbnailPlaylistOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50px;
    background: rgba(0,0,0, 0.8);
    text-align: center;
}

.thumbnailPlaylistOverlay .fa {
    color: #eeeeee;
    padding-top: 38px;
}

.absoluteCenter {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
}


.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: -1;
}

.footer, .footer a {
    color: gray;
    font-size: small;
}

.videoListCardMoveUp {
    margin-top: 21px;
    margin-bottom: 11px
}

.videoListCardMoveUp, .videoListCardMoveDown {
    text-align: center;
    font-size: small;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 1px solid #6c757d;
    color: #6c757d;
    cursor: pointer;
}

.videoListCardMoveUp:hover, .videoListCardMoveDown:hover {
    background-color: #6c757d;
    color: white;
}

.videoListCardMoveUp i, .videoListCardMoveDown i {
    margin-top: 5px
}

.videoListCardDelete {
    margin-left: -15px
}

.videoListCardThumbnail {
    margin-top: 5px;
}
